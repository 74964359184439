import Vue from 'vue'
import VueI18n from 'vue-i18n';
import langFile from "./lang.json";
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueI18n);

const messages = {
  en: {
    ...langFile.en,
  },
  zh: {
    ...langFile.zh,
  }
}

const i18n = new VueI18n({
  locale: 'zh',
  messages,
})

new Vue({
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
