import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    langcode: 'zh',
  },
  getters: {
  },
  mutations: {
    setLang(nextState, langcode){
      nextState.langcode = langcode;
    },
  },
  actions: {
    async changeLang(context, langcode){
      context.commit('setLang', langcode);
    },
  },
  modules: {
  }
})
