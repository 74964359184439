<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>JOINUS LIMITED</h1>
          <div class="description" v-if="langcode == 'en' ">
            A company that provides consulting services for clothing purchasing materials.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            一間經營製衣買料顧問服務的公司。
          </div>
          <ul class="icons">
            <li>{{ $t('Address') }}: {{ $t('FLAT C 25/F BLOCK 21 HONG KONG GARDEN 100 CASTLE PEAK ROAD, TSING LUNG TAU, NT')}}</li>
            <li>{{ $t('Tel') }}: 852-69981318</li>
          </ul>
          <a href="#" @click="viewAboutUs" style="margin-right: 10px">
            {{ $t('Company Overview')}}
          </a>
          <a href="#" @click="viewService">
            {{ $t('Our Service')}}
          </a>

          <div class="partner">
            {{ $t('合作伙伴') }}
            <div class="logos">
              <div class="logo logo1">
                <img src="/images/fulls/logo1.jpeg" />
              </div>
              <div class="logo logo2">
                <img src="/images/fulls/logo2.jpeg" />
              </div>
              <div class="logo logo3">
                <img src="/images/fulls/logo3.jpeg" />
              </div>
            </div>
          </div>
        </header>

        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />

        <!-- Footer -->
          <footer id="footer">
            <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
            <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
            <ul class="copyright">
              <li>&copy;  JOINUS LIMITED.</li>
            </ul>
          </footer>
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Company Overview</h1>
          <h1 v-else>公司概要</h1>
          <div class="description" v-if="langcode == 'en' ">
            Mainly assists foreign orders to find suitable raw material matching in China or Taiwan and provides consulting services.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            主要協助外國訂單在國內或是台灣尋找合適的原料配對及提供顧問服務。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
    <div class="our-service">
      <div id="main-3">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode == 'en' ">Our Service</h1>
          <h1 v-else>我們的服務</h1>
          <div class="description" v-if="langcode == 'en' ">
            Our service is an intermediary for all kinds of footwear, handbag raw materials and finished garments. Our colleagues are mainly responsible for taking orders and quoting, or they may need to go out to find manufacturers and suppliers to meet the requirements of all customers. The company focuses on purchasing raw materials domestically and exporting them to other countries. The company is responsible for paying the suppliers and collecting payments from foreign countries.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            各項鞋類、手袋原材料及製衣成品的中間人，我們的同事主要負責接單及報價，或需要出去找廠家及供應商去配合所有客人的要求。公司主力發展在國內採購原材料，並出口到其他國家。公司負責付款到供應的廠家，以及收取外國的貨款。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/03.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    //this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to JOINUS. How can I help you? ',
          disableInput: false,
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'answer': "Our Business Hour is 10:00am - 17:00pm.",
        },
        {
          'question': 'place_order',
          'answer': 'If you have any questions about us, please call 852-69981318 for further follow-up after receiving the goods.',
        },
        {
          'question': 'product_source',
          'answer': "Our product come from different place, such as Korea or Japan.",
        },
        {
          'question': 'order_instruction',
          'answer': "If you want to order product from us, please feel free to contact us with our phone or email address.",
        },
        {
          'question': 'product_list',
          'answer': "Currently, we do not had an online product list. If had any question about it, please contact with us.",
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    viewAboutUs(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewService(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.our-service');
      about.style.display = 'block';
    },
    backIndex(){
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const service = document.querySelector('.our-service');
      service.style.display = 'none';
    },
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      const question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });

      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if(answer){
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      }else{
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'These answer maybe able to help you:',
          disableInput: false,
          options: [
            {
              text: 'Ordering instructions',
              value: 'order_instruction',
              action: 'postback' // Request to API
            },
            {
              text: 'Product Source',
              value: 'product_source',
              action: 'postback' // Request to API
            },
            {
              text: 'Product List',
              value: 'product_list',
              action: 'postback' // Request to API
            },
            {
              text: 'Contact Method',
              value: 'place_order',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board{
  padding-bottom: 30px;
}
#viewer .slide .image{
  @media screen and (min-width: 1300px){
    background-size: 100%;
  }
}
.about-us, .our-service{
  display: none;
}
.partner{
  margin-top: 50px;
  .logos{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  img{
    display: block;
    width: 100%;
    max-width: 100px;
    margin-right: 10px;
  }
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>
